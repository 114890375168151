export interface IDTOUsuarioPrivilegio {
    identificador: string;
    descricao: string;
    valor: boolean;
}

const listaPadraoPrivilegiosUsuario: IDTOUsuarioPrivilegio[] = [
  { identificador: 'CONFIGURACOES', descricao: 'Configurações', valor: false },
  { identificador: 'USUARIOS', descricao: 'Usuários', valor: false },
  { identificador: 'FORMAS_PAGAMENTOS', descricao: 'Formas de Pagamentos', valor: false },
  { identificador: 'CLIENTES', descricao: 'Clientes', valor: false },
  { identificador: 'AREAS', descricao: 'Áreas', valor: false },
  { identificador: 'EQUIPAMENTOS', descricao: 'Equipamentos', valor: false },
  { identificador: 'RESERVAS', descricao: 'Reservas', valor: false },
];

export default listaPadraoPrivilegiosUsuario;
